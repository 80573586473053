import styled from "styled-components";

export const StyledTestimonialsPage = styled.section`
	.hero-greeting {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
		text-align: center;
	}

	.testimonials-section {
		padding: 2rem 12rem;
		scroll-margin-top: 2.5rem;

		@media screen and (max-width: 768px) {
			padding: 2rem 2rem 0;
		}

		@media screen and (min-width: 1600px) {
			padding: 3rem 20rem;
		}
	}
`;
