import React, { useState, useRef, useEffect } from "react";
import { StyledNav, StyledNavbar } from "./styledComponents";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MobileNav from "./../mobileNav";
import nfConsultWhite from "./../../images/nf-consult-white.svg";

export default function Navbar() {
	const [open, setOpen] = useState(false);
	const footerRef = useRef(null);

	useEffect(() => {
		window.addEventListener("scroll", updateNavBackground);
		return () => {
			window.removeEventListener("scroll", updateNavBackground);
		};
	}, []);

	const returnHome = () => {
		return (window.location.href = "/");
	};

	const updateNavBackground = () => {
		const navbar = document.querySelector("#navbar");
		const navbarHeight = navbar.offsetHeight;
		const scrollY = window.scrollY;

		if (scrollY > navbarHeight) {
			navbar.classList.add("blur-background");
		} else {
			navbar.classList.remove("blur-background");
		}
	};

	const handleMobileNav = () => {
		setOpen(!open);
	};

	const scrollToFooter = () => {
		footerRef.current = document.querySelector("#main-footer");

		if (footerRef.current) {
			footerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const navigate = (e) => {
		const target = e.target;

		console.log(window.location.pathname);
		if (window.location.pathname === "/") {
			if (target.innerText === "Home") {
				window.scrollTo(0, 0);
			} else if (target.innerText === "About") {
				document
					.querySelector("#aboutus")
					.scrollIntoView({ behavior: "smooth" });
			} else if (target.innerText === "Services") {
				document
					.querySelector("#services")
					.scrollIntoView({ behavior: "smooth" });
			} else if (target.innerText === "Testimonials") {
				document
					.querySelector("#testimonials")
					.scrollIntoView({ behavior: "smooth" });
			}
		} else {
			if (target.innerText === "Home") {
				window.location.href = "/";
			} else if (target.innerText === "About") {
				window.location.href = "/";
			} else if (target.innerText === "Services") {
				window.location.href = "/services";
			} else if (target.innerText === "Testimonials") {
				window.location.href = "/testimonials";
			}
		}
	};

	return (
		<>
			<MobileNav open={open} setOpen={setOpen} />
			<StyledNavbar className="scroll-smooth">
				<StyledNav id="navbar" className="backdrop-blur-sm">
					<div className="logo" onClick={returnHome}>
						<img src={nfConsultWhite} alt="NF Consult Logo" />
					</div>
					<div className="links">
						<div href="#home" onClick={(e) => navigate(e)} className="link">
							Home
						</div>
						<div href="/#aboutus" onClick={(e) => navigate(e)} className="link">
							About
						</div>
						<div
							href="/#services"
							onClick={(e) => navigate(e)}
							className="link"
						>
							Services
						</div>
						<div
							href="/#testimonials"
							onClick={(e) => navigate(e)}
							className="link"
						>
							Testimonials
						</div>
					</div>
					<button className="cta-button" onClick={scrollToFooter}>
						Contact
					</button>
					<div className="hamburger" onClick={handleMobileNav}>
						<MenuRoundedIcon fontSize="large" />
					</div>
				</StyledNav>
			</StyledNavbar>
		</>
	);
}
