import React, { useEffect, useRef } from "react";
import { StyledMobileNav } from "./styled";
import nfConsultWhite from "./../../images/nf-consult-white.svg";

export default function MobileNav({ open, setOpen }) {
	const footerRef = useRef(null);
	const menuRef = useRef(null);

	const scrollToFooter = () => {
		footerRef.current = document.querySelector("#main-footer");

		if (footerRef.current) {
			footerRef.current.scrollIntoView({ behavior: "smooth" });
		}
		setOpen(false);
	};

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const navigate = (e) => {
		const target = e.target;

		console.log(window.location.pathname);
		if (window.location.pathname === "/") {
			if (target.innerText === "Home") {
				window.scrollTo(0, 0);
			} else if (target.innerText === "About") {
				document
					.querySelector("#aboutus")
					.scrollIntoView({ behavior: "smooth" });
			} else if (target.innerText === "Services") {
				document
					.querySelector("#services")
					.scrollIntoView({ behavior: "smooth" });
			} else if (target.innerText === "Testimonials") {
				document
					.querySelector("#testimonials")
					.scrollIntoView({ behavior: "smooth" });
			}
		} else {
			if (target.innerText === "Home") {
				window.location.href = "/";
			} else if (target.innerText === "About") {
				window.location.href = "/";
			} else if (target.innerText === "Services") {
				window.location.href = "/services";
			} else if (target.innerText === "Testimonials") {
				window.location.href = "/testimonials";
			}
		}

		setOpen(false);
	};

	return (
		<StyledMobileNav ref={menuRef} className="mobile-nav" open={open}>
			<div className="logo">
				<img src={nfConsultWhite} alt="Website Logo" />
			</div>
			<div className="links">
				<div href="#home" onClick={(e) => navigate(e)} className="link">
					Home
				</div>
				<div href="#aboutus" onClick={(e) => navigate(e)} className="link">
					About
				</div>
				<div href="#services" onClick={(e) => navigate(e)} className="link">
					Services
				</div>
				<div href="#testimonials" onClick={(e) => navigate(e)} className="link">
					Testimonials
				</div>
			</div>
			<button className="cta-button" onClick={() => scrollToFooter()}>
				Contact
			</button>
		</StyledMobileNav>
	);
}
