import styled from "styled-components";

export const StyledCard = styled.div`
	.card {
		display: flex;
		flex-direction: row;
		column-gap: 3rem;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		padding: 2rem;
		border-radius: 10px;
		box-shadow: 0 20px 75px rgba(0, 0, 0, 0.1);
		width: 100%;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			row-gap: 1rem;
			margin: 0;
			padding: 1rem;
			height: 32rem;
		}

		@media screen and (max-width: 375px) {
			height: 34rem;
		}
	}

	.card-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 50%;

		@media screen and (max-width: 768px) {
			align-items: center;
			text-align: center;
			order: 2 !important;
			width: 100%;
			height: 60%;
			gap: 0.25rem;
			justify-content: space-around;
		}
	}

	.card-title {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;

		@media screen and (max-width: 768px) {
			text-align: center;
			margin-bottom: 0;
			font-size: 1.5rem;
		}
	}

	.card-description {
		font-size: 1.2rem;
		font-weight: 300;
		text-align: left;

		@media screen and (max-width: 768px) {
			text-align: center;
		}
	}

	.card-button {
		margin-top: 1rem;
	}

	.card-image {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: 50%;

		img {
			width: 100%;
			height: 20rem;
			border-radius: 10px;

			@media screen and (max-width: 768px) {
				width: 100%;
				height: 100%;
			}
		}

		@media screen and (max-width: 768px) {
			order: 1 !important;
			width: 100%;
			height: 40%;
		}
	}
`;
