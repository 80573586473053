import React from "react";
import { StyledCard } from "./styled";
import Button from "../button";

export default function Card({
	service,
	onClick,
	btnText,
	textOrder,
	imageOrder,
}) {
	return (
		<StyledCard>
			<div key={service.index} className="card">
				<div className="card-content" style={{ order: textOrder }}>
					<h1 className="card-title">{service.serviceTitle}</h1>
					<p className="card-description">{service.serviceDesc}</p>
					<Button className="card-button" onClick={onClick}>
						{btnText}
					</Button>
				</div>
				<div className="card-image" style={{ order: imageOrder }}>
					<img src={service.serviceImage} alt={service.serviceImageAlt} />
				</div>
			</div>
		</StyledCard>
	);
}
