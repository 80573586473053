import styled from "styled-components";

export const StyledMobileNav = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	margin-left: ${({ open }) => (open ? "0" : "-286px")};
	width: ${({ open }) => (open ? "75%" : "0%")};
	z-index: 3;
	position: fixed;
	background-color: var(--primary);
	padding: 2rem 1.25rem;
	top: 0;
	z-index: 2000;

	transition: all 0.75s ease-in-out;

	.logo {
		margin-bottom: 1.5rem;
		margin-left: 1rem;
		width: 50%;
		height: auto;
	}

	.links {
		display: flex;
		flex-direction: column;
		row-gap: 0.75rem;
	}

	.link {
		color: #fff;
		font-size: 1.05rem;
		font-weight: 500;
		text-decoration: none;
		padding: 0.5rem 1rem;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;
		width: fit-content;

		&:hover {
			transform: scale(1.15) !important;
			animation: shadow-white 1s ease-in-out infinite;
		}
	}

	.contact {
		background: radial-gradient(
			at 75% 75%,
			rgb(0, 140, 255, 0.5) 0,
			rgba(0, 19, 34, 1) 100%
		);
		animation: gradient 15s ease-in-out infinite;
		background-size: 600% 600%;
		transition: all 0.3s ease-in-out;
		border-radius: 20px;
		color: #fff;
		font-size: 1.05rem;
		font-weight: 500;
		margin-top: auto;
		text-decoration: none;
		padding: 0.75rem 1.5rem;
		box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);

		&:hover {
			transform: scale(1.1) !important;
			animation: shadow-blue 1s ease-in-out infinite,
				gradient 7s ease-in-out infinite;
		}
	}

	.cta-button {
		width: fit-content;
		transition: all 0.3s ease-in-out;
		border-radius: 10px;
		color: #fff;
		font-size: 1.05rem;
		font-weight: 500;
		margin-top: auto;
		text-decoration: none;
		padding: 0.75rem 1.75rem;
		box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
		border: none;
		background-color: var(--primary-dark);

		&:hover {
			transform: scale(1.1) !important;
			background-color: var(--primary);
		}
	}
`;
