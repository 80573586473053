import styled from "styled-components";

export const StyledAboutUs = styled.section`
	scroll-margin-top: 5.5rem;
	background-color: #dfdfdf;

	.about-us-container {
		padding: 3rem 12rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 768px) {
			padding: 3rem 2rem;
		}

		@media screen and (min-width: 1600px) {
			padding: 3rem 20rem;
		}
	}

	.about-us-title {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
	}

	.about-us-description {
		font-size: 1.2rem;
		font-weight: 300;
		text-align: center;
	}

	.about-us-emphasized {
		margin-top: 1rem;
		font-size: 1.3rem;
		font-weight: 500;
	}
`;
