import strausDaly from "../../images/sd-attorneys-logo.png";
import kgb from "../../images/kgb-logo.png";
import accountabilityLogo from "../../images/accountability-logo.webp";

export const testimonials = [
	{
		name: "Diane Kemp",
		position: "Director & Head of the Branch",
		company: "Strauss Daly - Claremont",
		desc: [
			"Nantes Fourie has been servicing our offices for a couple of years and has always displayed a great work ethic.  We can rely on our calls being answered any time of the day and thereafter provided with a prompt service to quickly and efficiently remedy our IT related issues.  Very helpful and knowledgeable about the task at hand for which we are very grateful",
			"",
		],
		image: strausDaly,
		imageAlt: "Strauss Daly Logo",
		buttonText: "Read More",
	},
	{
		name: "Conrad Groenewald",
		position: "Operation Manager",
		company: "Koegelenberg Attorneys",
		desc: [
			"I am writing to enthusiastically endorse NF Consult as a vital partner in our IT ecosystem for the past five years. Nantes, the founder of NF Consult, consistently goes above and beyond to ensure the seamless operation and efficiency of our desktop, IT infrastructure, and network support.",
			"Nantes proved to be an indispensable asset during the onset of the COVID-19 pandemic. His swift response and expert guidance enabled our organization to swiftly transition to a fully remote work environment in just under two weeks from the initial lockdown announcement. This feat was a testament to his exceptional problem-solving skills, dedication, and ability to keep our operations running without a hitch.",
			"One of the most commendable aspects of NF Consult's service is their unwavering commitment to working within our established budget constraints and adhering to strict timelines. Their ability to deliver exceptional results while respecting our financial boundaries is truly remarkable.",
			"I wholeheartedly recommend NF Consult for your business and IT needs. Their consistent performance and dedication to our success have made them an invaluable partner, and I am confident they will bring the same level of excellence and commitment to any organization fortunate enough to work with them.",
		],
		image: kgb,
		imageAlt: "KGB Logo",
		buttonText: "Read More",
	},
	{
		name: "Elmien Rupping",
		position: "Owner",
		company: "Accountability Professional Accountants Pty Ltd",
		desc: [
			"We have found that NF Consult understands the amount of work and stress that is put on a SME business owner’s shoulder every day. NF Consult is not only a Business Technology Solutions provider, but they strive to be our IT business partner that decrease computer downtime and want to help lighten the load by making sure that our IT needs are taken care of in a reliable and cost-effective way. Our experience is that the services provided by NF Consult is for one main goal: streamlining to ensure efficiency of all our business IT to help us do business better. Nantes and NF Consult know their stuff and are always able to help with remote or in-house advice and solutions. Most reliable IT service in Cape Town.",
			"We have recommended NF Consult and will continue to as we feel the service is excellent and the value for money is great, awesome company to do business with. Always helpful. Good fast service. They really go out of their way to source the hardware, software, etc to meet our IT requirements. Thank you, NF Consult-team!",
		],
		image: accountabilityLogo,
		imageAlt: "Accountability Professional Accountants Logo",
		buttonText: "Read More",
	},
	{
		name: "Shelley Feldman",
		position: "Director & Head of the Department",
		company: "Strauss Daly - Cape Town",
		desc: [
			"I have worked with Nantes for over 6 years, who has provided exceptional IT services to Strauss Daly. Nantes is extremely knowledgeable, reliable and above all patient. I would highly recommend him.",
		],
		image: strausDaly,
		imageAlt: "Strauss Daly Logo",
		buttonText: "Read More",
	},
];
