import React from "react";
import { StyledButton, StyledButtonContainer } from "./styled";

export default function Button({ id, children, className, onClick, disabled }) {
	return (
		<StyledButtonContainer className="button-container">
			<StyledButton
				id={id}
				onClick={onClick}
				className={`${className} ${disabled === true ? "disabled" : ""}`}
			>
				{children}
			</StyledButton>
		</StyledButtonContainer>
	);
}
