import styled from "styled-components";

const StyledNavbar = styled.div`
	.blur-background {
		backdrop-filter: blur(10px);
		background-color: #00000050;
		transition: all 0.3s ease-in-out;
	}

	.no-background {
		backdrop-filter: blur(0px) !important;
		background-color: transparent !important;
		transition: all 0.3s ease-in-out !important;
	}
`;

const StyledNav = styled.nav`
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	padding: 1rem 1rem;
	box-sizing: border-box;
	font-family: Poppins, sans-serif;
	position: fixed;

	.blur-background {
		backdrop-filter: blur(20px);
		background-color: #0067c450;
	}

	.logo {
		color: #ffffff;
		font-weight: 600;

		img {
			height: 3rem;
			transition: all 0.3s ease-in-out;

			&:hover {
				cursor: pointer;
				transform: scale(1.1) !important;
				animation: shadow-blue 1s ease-in-out infinite;
			}
		}
	}

	.links {
		display: flex;
		justify-content: space-between;
		column-gap: 2.5rem;
	}

	@media screen and (max-width: 768px) {
		padding: 1rem 2rem;

		.hamburger {
			display: block !important;
			cursor: pointer;
			z-index: 2;
		}

		.links {
			display: none;
		}

		.contact {
			display: none;
		}
	}

	.hamburger {
		display: none;

		&:hover {
			background-color: transparent;
		}

		&:focus {
			outline: none;
			background-color: transparent;
		}
	}

	.cta-button {
		width: fit-content;
		transition: all 0.3s ease-in-out;
		border-radius: 10px;
		color: #fff;
		font-size: 1rem;
		font-weight: 500;
		text-decoration: none;
		padding: 0.5rem 1.5rem;
		box-shadow: 0 0 1.25rem 0.25rem #acb3a920;
		border: none;
		background-color: var(--primary);

		&:hover {
			transform: scale(1.1) !important;
			background-color: var(--primary-dark);
		}

		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}

	.link {
		text-decoration: none;
		color: #ffffff;
		transition: color 0.4s ease;
		box-sizing: border-box;
		position: relative;
		padding: 0 0.5rem;

		&:hover:after {
			width: 100%;
		}
	}

	.link:after {
		content: "";
		display: block;
		position: absolute;
		width: 0%;
		height: 2px;
		border-radius: 2px;
		left: 0;
		right: 0;
		bottom: -6px;
		background-color: #ffffff;
		transition: width 0.3s;
	}
`;

export { StyledNav, StyledNavbar };
