import styled from "styled-components";

export const StyledServices = styled.section`
	scroll-margin-top: 4.5rem;

	@media screen and (max-width: 768px) {
		scroll-margin-top: 3.5rem;
	}

	.services-container {
		padding: 3rem 12rem;

		@media screen and (max-width: 768px) {
			padding: 3rem 1rem;
		}

		@media screen and (min-width: 1600px) {
			padding: 3rem 20rem;
		}
	}

	.services-heading {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
		text-align: center;

		@media screen and (max-width: 768px) {
			margin-bottom: 1rem;
		}
	}

	/* .slick-track {
		display: flex;
		align-items: stretch;

		.slick-slide {
			display: flex;
			height: auto;
			flex-wrap: wrap;

			align-items: center;
			justify-content: space-between;
		}
	} */

	.service-slider-wrap {
		margin-left: 0x;
		margin-right: 0px;
	}

	.slick-next:before,
	.slick-prev:before {
		color: var(--primary);
		font-size: 1.75rem;
	}

	.slick-next {
		right: -40px;

		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}

	.slick-prev {
		left: -60px;

		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}

	.service-slider-wrap .slick-dots {
		display: -webkit-box;
		display: -ms-flexbox;
		display: inline-block !important;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding-top: 3rem;
	}
	.service-slider-wrap .slick-dots li {
		cursor: pointer;
		overflow: hidden;
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
		position: relative;
		width: 22px;
		height: 22px;
		margin-top: 1rem;
		background: transparent;
		line-height: 22px;
		border-radius: 50%;
		text-align: center;
		border: 1px solid transparent;
	}
	.service-slider-wrap .slick-dots li button {
		text-indent: 100px;
		background: transparent;
	}
	.service-slider-wrap .slick-dots li:before {
		content: "";
		width: 5px;
		height: 5px;
		left: 50%;
		top: 50%;
		position: absolute;
		border-radius: 50%;
		background: var(--primary);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.service-slider-wrap .slick-dots li.slick-active {
		border-color: var(--primary);
	}
	.service-slider-wrap .slick-dots li.slick-active:before {
		background: var(--primary);
	}

	.slick-slide {
		padding: 2rem;

		@media screen and (max-width: 768px) {
			padding: 1rem;
		}
	}
`;
