import React from "react";
import { StyledHero } from "./styled";
import Button from "../button";
import { TypeAnimation } from "react-type-animation";

export default function Hero({
	heroImage,
	sequence,
	greeting,
	tagline,
	cta,
	onClick,
}) {
	const typeSequence = (
		<TypeAnimation
			sequence={sequence}
			wrapper="span"
			speed={10}
			repeat={Infinity}
			className="hero-greeting"
		/>
	);

	return (
		<StyledHero id="home" heroimage={heroImage}>
			<div className="hero-container">
				<div className="hero-content">
					{(greeting || sequence) && (
						<h1 className="hero-greeting">
							{greeting} {sequence ? typeSequence : ""}
							{sequence && "?"}
						</h1>
					)}
					{tagline && <p className="hero-tag-line">{tagline}</p>}
					{cta && (
						<div className="hero-cta">
							<Button className="hero-cta-button" onClick={onClick}>
								{cta}
							</Button>
						</div>
					)}
				</div>
				<div className="hero-image" />
			</div>
		</StyledHero>
	);
}
