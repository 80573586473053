import styled from "styled-components";

export const StyledTestimonials = styled.section`
	padding: 3rem 12rem;

	@media screen and (max-width: 768px) {
		padding: 3rem 1rem 2rem;
	}

	@media screen and (min-width: 1600px) {
		padding: 3rem 20rem;
	}

	.testimonials-container {
		scroll-margin-top: 7.5rem;
	}

	.testimonials-card-container {
		box-sizing: border-box;
		margin: 0 2rem;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			row-gap: 3rem;
			margin: 0;
		}
	}

	.testimonials-card {
		height: 18rem;
		display: flex !important;
		flex-direction: column;
		gap: 1rem;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		background-color: #fff;
		padding: 2rem;
		border-radius: 10px;
		box-shadow: 0 20px 75px rgba(0, 0, 0, 0.1);

		background-color: #e4e4e4;

		transition: all 0.3s ease-in-out;

		&:hover {
			cursor: pointer;
			transform: scale(1.05);
		}

		color: #000000;

		@media screen and (max-width: 768px) {
			width: 100%;
			height: 28rem;
			padding: 1rem;
			gap: 0.5rem;
		}
	}

	.testimonials-heading {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
		text-align: center;
	}

	.testimonials-description {
		font-size: 1.2rem;
		font-weight: 300;
		text-align: center;
		text-overflow: ellipsis;
		word-wrap: break-word;
		white-space: wrap;
	}

	.testimonials-info-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.testimonials-image-desktop {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		img {
			width: 10rem;
			height: auto;

			@media screen and (max-width: 768px) {
				width: 7rem;
				display: none;
			}
		}
	}

	.testimonials-image-mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		margin-top: 1rem;
		display: none;

		@media screen and (max-width: 768px) {
			display: flex;
		}

		img {
			@media screen and (max-width: 768px) {
				width: 10rem;
				display: flex;
			}
		}
	}

	.testimonials-sub-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: 2rem;

		@media screen and (max-width: 768px) {
			margin-left: 0.5rem;
			margin-top: 1rem;
			align-items: center;
			text-align: center;
			justify-content: center;
		}

		.testimonials-name {
			font-size: 1.2rem;
			font-weight: 600;
			margin-bottom: 0.5rem;
		}

		.testimonials-position {
			font-size: 1rem;
			font-weight: 300;
		}

		.testimonials-company {
			font-size: 1rem;
			font-weight: 500;
			color: var(--primary-dark);
		}
	}

	.slick-next:before,
	.slick-prev:before {
		color: var(--primary);
		font-size: 1.75rem;
	}

	.slick-next {
		right: -40px;

		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}

	.slick-prev {
		left: -60px;

		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}

	.testimonial-slider .slick-dots {
		display: -webkit-box;
		display: -ms-flexbox;
		display: inline-block !important;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding-top: 3rem;
	}

	.testimonial-slider .slick-dots li {
		cursor: pointer;
		overflow: hidden;
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
		position: relative;
		width: 22px;
		height: 22px;
		margin-top: 1rem;
		background: transparent;
		line-height: 22px;
		border-radius: 50%;
		text-align: center;
		border: 1px solid transparent;
	}
	.testimonial-slider .slick-dots li button {
		text-indent: 100px;
		background: transparent;
	}
	.testimonial-slider .slick-dots li:before {
		content: "";
		width: 5px;
		height: 5px;
		left: 50%;
		top: 50%;
		position: absolute;
		border-radius: 50%;
		background: var(--primary);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.testimonial-slider .slick-dots li.slick-active {
		border-color: var(--primary);
	}
	.testimonial-slider .slick-dots li.slick-active:before {
		background: var(--primary);
	}

	.slick-slide {
		padding: 2rem 1rem;

		@media screen and (max-width: 768px) {
			padding: 1rem;
		}
	}

	.slick-slider {
		@media screen and (max-width: 768px) {
			padding: 1rem;
		}
	}
`;
