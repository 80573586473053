import styled from "styled-components";

export const StyledHero = styled.section`
	box-sizing: border-box !important;

	.hero-container {
		display: flex;
		position: relative;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100%;
		background-color: #000000;
		color: #ffffff;
		box-sizing: border-box !important;
		overflow: hidden;

		&:before {
			content: "";
			position: absolute;
			z-index: 0;
			width: 100%;
			height: 100%;
			background-image: url(${(props) => props.heroimage});
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: bottom center;
			filter: brightness(0.5);
			animation: fadeBackground 5s ease-in-out infinite alternate;
			box-sizing: border-box !important;

			@media screen and (max-width: 768px) {
				background-position: center;
				background-size: cover;
				background-clip: border-box;

				animation: fadeBackgroundMobile 5s ease-in-out infinite alternate;
			}
		}

		@keyframes fadeBackground {
			from {
				background-size: 100% 100%;
			}
			to {
				background-size: 110% 110%;
			}
		}

		@keyframes fadeBackgroundMobile {
			from {
				transform: scale(1);
			}
			to {
				transform: scale(1.1);
			}
		}
	}

	.hero-content {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 1rem;
		width: 50%;
		height: 100%;
		padding: 0 2rem;

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.hero-greeting {
		font-size: 4rem;
		font-weight: 700;

		@media screen and (max-width: 768px) {
			font-size: 2rem;
			text-align: center;
		}
	}

	.hero-tag-line {
		font-size: 2rem;
		font-weight: 400;

		@media screen and (max-width: 768px) {
			font-size: 1.25rem;
			text-align: center;
		}
	}

	.hero-cta {
		margin-top: 1.5rem;

		.hero-cta-button {
			font-size: 1.25rem;
		}
	}
`;
