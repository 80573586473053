import React, { useEffect, useState } from "react";
import { StyledFloatingButton } from "./styled";
import { ArrowUpwardRounded } from "@mui/icons-material";

export default function FloatingButton() {
	const scrollHome = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const [showButton, setShowButton] = useState(null);

	const currentScrollPosition = () => {
		const scrollPosition = window.pageYOffset;

		if (scrollPosition > 500) {
			setShowButton("show");
		} else {
			setShowButton(null);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", currentScrollPosition);
	}, []);

	return (
		<StyledFloatingButton show={showButton} onClick={() => scrollHome()}>
			<ArrowUpwardRounded className="scroll-home" />
		</StyledFloatingButton>
	);
}
