import React, { useState, useEffect, useRef } from "react";
import { StyledHome } from "./styled";
import {
	Page,
	Hero,
	AboutUs,
	Services,
	Testimonials,
	Clients,
} from "../../components";
import heroBackground from "./../../images/hero-background.jpg";
import stellies from "./../../images/stellies.jpg";
import beach from "./../../images/beach.jpg";
import greenpoint from "./../../images/greenpoint.jpg";

export default function Home() {
	const scrollToRef = useRef();

	const scrollTo = (element) => {
		scrollToRef.current = document.querySelector(element);

		if (scrollToRef.current) {
			scrollToRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const heroImages = [heroBackground, stellies, beach, greenpoint];
	const [heroImage, setHeroImage] = useState(heroImages[0]);
	const indexRef = useRef(0);

	const updateRandomImage = () => {
		indexRef.current = (indexRef.current + 1) % heroImages.length;
		setHeroImage(heroImages[indexRef.current]);
	};

	useEffect(() => {
		// Call the updateRandomImage function immediately
		updateRandomImage();

		// Set an interval to change the image every 3 seconds
		const interval = setInterval(updateRandomImage, 5500);

		// Clear the interval when the component unmounts to prevent memory leaks
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledHome>
			<Page>
				<Hero
					heroImage={heroImage}
					sequence={["Services", 2000, "Support", 2000, "Backup", 2000]}
					greeting={"Need IT"}
					tagline={"We've got you covered!"}
					cta={"Reach out"}
					onClick={() => scrollTo("#main-footer")}
				/>
				<AboutUs />
				<Services />
				<Clients />
				<Testimonials />
			</Page>
		</StyledHome>
	);
}
