import styled from "styled-components";

export const StyledTestimonialCard = styled.div`
	.testimonial-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 2rem;
		margin: 2rem;
		gap: 2rem;
		border-radius: 10px;
		width: 100%;

		box-shadow: 0 20px 75px rgba(0, 0, 0, 0.1);

		transition: all 0.3s ease-in-out;

		background-color: #e4e4e4;

		&:hover {
			transform: scale(1.01);
		}

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 1.5rem;
			margin: 1rem 0;
			padding: 2rem 1rem;
		}
	}

	.testimonial-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.testimonial-image {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		img {
			width: 100%;
			height: auto;

			@media screen and (max-width: 768px) {
				width: 50%;
				margin-bottom: 1rem;
			}
		}
	}

	.testimonial-name {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 100%;

		h3 {
			font-size: 1.5rem;
			font-weight: 600;
			margin: 0;
			margin-top: 1rem;

			@media screen and (max-width: 768px) {
				margin-top: 0;
			}
		}

		p {
			font-size: 1rem;
			font-weight: 400;
			margin: 0;
			margin-top: 0.5rem;
		}

		.testimonial-company {
			font-size: 1rem;
			font-weight: 500;
			margin-top: 0.5rem;
			color: var(--primary);
		}
	}

	.testimonial-quote {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: left;

		p {
			font-size: 1.2rem;
			font-weight: 400;
			margin-top: 0;

			@media screen and (max-width: 768px) {
				font-size: 1rem;
			}
		}

		p:not(:first-child) {
			margin-top: 1rem;

			@media screen and (max-width: 768px) {
				margin-top: 0.5rem;
			}
		}
	}
`;
