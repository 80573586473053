import React from "react";
import { StyledPage } from "./styled";
import Navbar from "../navbar";
import Footer from "../footer";
import { Helmet } from "react-helmet";
import FloatingButton from "../floatingButton";

export default function Page({ children }) {
	const currentURL = window.location.href;

	function formatUrl(url) {
		const path = url.substring(url.lastIndexOf("/") + 1);

		const words = path
			.split("-")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

		const formatted = words.join(" ");

		if (formatted === "") {
			return "Home";
		}

		return formatted;
	}

	return (
		<StyledPage>
			<Helmet>
				<title key="title">{formatUrl(currentURL)} | NF Consult</title>
			</Helmet>
			<Navbar />
			{children}
			<Footer />
			<FloatingButton />
		</StyledPage>
	);
}
