import React from "react";
import { StyledTestimonialsPage } from "./styled";
import { Page, Hero, TestimonialCard } from "../../components";
import heroImage from "./../../images/testimonial-hero.jpg";
import { testimonials } from "../../components/testimonials/testimonialsConfig";

export default function Testimonials() {
	const scrollToTestimonials = () => {
		const testimonialsSection = document.querySelector("#testimonialsSection");
		if (testimonialsSection) {
			testimonialsSection.scrollIntoView({ behavior: "smooth" });
		}
	};

	let windowWidth = window.innerWidth;

	return (
		<Page>
			<StyledTestimonialsPage>
				<Hero
					heroImage={heroImage}
					greeting={"A Few Words From Our Clients"}
					cta={"See All"}
					onClick={scrollToTestimonials}
				/>
				<div className="testimonials-section" id="testimonialsSection">
					{testimonials.map((testimonial, index) => (
						<TestimonialCard
							key={index}
							image={testimonial.image}
							imageAlt={testimonial.imageAlt}
							name={testimonial.name}
							title={testimonial.position}
							quote={testimonial.desc}
							infoOrder={windowWidth < 768 ? 1 : index % 2 === 0 ? 0 : 1}
							quoteOrder={windowWidth < 768 ? 2 : index % 2 === 0 ? 1 : 0}
							company={testimonial.company}
						/>
					))}
				</div>
			</StyledTestimonialsPage>
		</Page>
	);
}
