import office365 from "./../../images/office365.jpg";
import endpoint from "./../../images/endpoint.jpg";
import backup from "./../../images/backup.jpg";
import security from "./../../images/network-security.jpg";
import admin from "./../../images/network-admin.jpg";
import mail from "./../../images/mail.jpg";
import support from "./../../images/support.jpeg";

export const services = [
	{
		serviceTitle: "01 - Desktop Support",
		serviceDesc:
			"We offer desktop support. This includes on-site and remote support. ",
		serviceImage: support,
		serviceImageAlt: "Consulting",
	},
	{
		serviceTitle: "02 – Network Administration",
		serviceDesc:
			"Deployment and configuration of new servers and network infrastructure.",
		serviceImage: admin,
		serviceImageAlt: "Desktop Support",
	},
	{
		serviceTitle: "03 – Network Security",
		serviceDesc:
			"Ensure network integrity and protection from the outside world.",
		serviceImage: security,
		serviceImageAlt: "Desktop Support",
	},
	{
		serviceTitle: "04 – Mail Server Management",
		serviceDesc:
			"Monitor and maintain the company’s email system, whether on-site or cloud.",
		serviceImage: mail,
		serviceImageAlt: "Desktop Support",
	},
	{
		serviceTitle: "05 – Office365 Management",
		serviceDesc: "We offer full Office356 organization administration.",
		serviceImage: office365,
		serviceImageAlt: "Desktop Support",
	},
	{
		serviceTitle: "06 – Endpoint Management and Deployment",
		serviceDesc:
			"Maintain functionality and safeguard devices, data, and other assets from cyber threats.",
		serviceImage: endpoint,
		serviceImageAlt: "Desktop Support",
	},
	{
		serviceTitle: "07 – Backup & Redundancy",
		serviceDesc:
			"Data loss prevention, quick redeployment and continuity of operations.",
		serviceImage: backup,
		serviceImageAlt: "Desktop Support",
	},
];
