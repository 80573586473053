import React from "react";
import { StyledServices } from "./styled";
import { services } from "./servicesConfig";
import Slider from "react-slick";
import Card from "../card";

export default function Services() {
	const settings = {
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const navigateToServices = () => {
		window.location.href = "/services";
	};

	return (
		<StyledServices id="services">
			<div className="services-container">
				<h1 className="services-heading">Our Services</h1>
				<div className="service-slider-wrap">
					<Slider {...settings}>
						{services.map((service, index) => (
							<Card
								key={index}
								service={service}
								onClick={navigateToServices}
								btnText={"Learn More"}
								textOrder={2}
								imageOrder={1}
							/>
						))}
					</Slider>
				</div>
			</div>
		</StyledServices>
	);
}
