import React from "react";
import { StyledClients } from "./styled";
import Slider from "react-slick";
import accountabilityLogo from "./../../images/accountability-logo.webp";
import kgbLogo from "./../../images/kgb-logo.png";
import sdAttorneysLogo from "./../../images/sd-attorneys-logo.png";
import rightsolLogo from "./../../images/rs-logo.svg";
import woulfLogo from "./../../images/woulf-logo.png";
import gmhLogo from "./../../images/gmh.png";
import finhubLogo from "./../../images/finhub.png";

export default function Clients() {
	const clientSettings = {
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		speed: 2000,
		rtl: true,
		slidesToShow: 2,
		slidesToScroll: 1,
	};
	const partnerSettings = {
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<StyledClients>
			<div className="clients-container">
				<h1 className="clients-heading">Our Clients</h1>
				<div className="clients-logos">
					<Slider {...clientSettings}>
						<div className="client">
							<a
								className="clients-logo"
								href="https://www.accountabilitypa.co.za/"
								target="_blank"
								rel="noreferrer"
							>
								<img src={accountabilityLogo} alt="AccountibilityPA Logo" />
							</a>
						</div>
						<div className="client">
							<a
								className="clients-logo"
								href="https://www.straussdaly.co.za/"
								target="_blank"
								rel="noreferrer"
							>
								<img src={sdAttorneysLogo} alt="Strauss Daly Attorneys Logo" />
							</a>
						</div>
						<div className="client">
							<a
								id="kgb-logo"
								className="clients-logo"
								target="_blank"
								href="https://www.kgblaw.co.za/"
								rel="noreferrer"
							>
								<img src={kgbLogo} alt="KGB Law Logo" />
							</a>
						</div>
						<div className="client">
							<a
								id="finhub-logo"
								className="clients-logo"
								target="_blank"
								href="https://www.finhub.co.za/"
								rel="noreferrer"
							>
								<img src={finhubLogo} alt="Finhub Logo" />
							</a>
						</div>
						<div className="client">
							<div id="gmh-logo" className="clients-logo">
								<img src={gmhLogo} alt="GMH" />
							</div>
						</div>
					</Slider>
				</div>
			</div>
			<div className="partner-container">
				<h1 className="partner-heading">Our Partners</h1>
				<div className="partner-logos">
					<Slider {...partnerSettings}>
						<div className="partner">
							<a
								className="partner-logo"
								href="https://www.rightsol.co.za/"
								target="_blank"
								rel="noreferrer"
							>
								<img src={rightsolLogo} alt="Right Solutions Logo" />
								<p className="partner-solution">
									We partner with Right Solutions for more advanced firewall and
									network monitoring, as well as server management and
									maintenance.
								</p>
							</a>
						</div>
						<div className="partner">
							<a
								id="woulf-logo"
								className="partner-logo"
								href="https://www.woulfdigitalstudio.co.za/"
								target="_blank"
								rel="noreferrer"
							>
								<img src={woulfLogo} alt="Woulf Digital Studio Logo" />
								<p className="partner-solution">
									We partner with Woulf Digital Studio to help clients with web
									design, web development and logo design needs.
								</p>
							</a>
						</div>
					</Slider>
				</div>
			</div>
		</StyledClients>
	);
}
