import React from "react";
import { StyledAboutUs } from "./styled";

export default function AboutUs() {
	return (
		<StyledAboutUs id="aboutus">
			<div className="about-us-container">
				<h1 className="about-us-title">About Us</h1>
				<p className="about-us-description">
					Welcome to NFconsult! Here to provide your all-in-one networking
					needs. Desktop support, network administration, backup & redundancy,
					we do it all. Whether you are a big or small business, we are here to
					help. If you are looking for professional IT support for your business
					or acquiring IT for the first time, you came to the right place.
				</p>
				<span className="about-us-emphasized">Let’s work together!</span>
			</div>
		</StyledAboutUs>
	);
}
