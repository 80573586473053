import React, { useRef, useState } from "react";
import {
	StyledFooter,
	StyledDivider,
	StyledFooterContainer,
} from "./styledComponents";
import Button from "../button";
// import emailjs from "@emailjs/browser";
// import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import nfConsultBlack from "./../../images/nf-consult-black.svg";

// import ReCAPTCHA from "react-google-recaptcha";

// const siteKey = process.env.REACT_APP_SITE_KEY;

export default function Footer() {
	const currentYear = new Date().getFullYear();
	const form = useRef();
	// const [emailSent, setEmailSent] = useState("");
	// const [recaptchad, setRecaptchad] = useState(false);
	const [error, setError] = useState(false);

	// const sendButton = document.getElementById("contact-form-button");

	// const updateButtonText = () => {
	// 	switch (emailSent) {
	// 		case "NotSent":
	// 			return "Try Again";
	// 		case "Sending":
	// 			return (
	// 				<SyncRoundedIcon fontSize="medium" className="animate-reverse-spin" />
	// 			);
	// 		default:
	// 			return "Send";
	// 	}
	// };

	const detectTyping = (e) => {
		if (e.target.value) {
			setError(false);
		}
	};

	// const sendEmail = (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();

	// 	if (
	// 		!form.current.user_name.value ||
	// 		!form.current.user_email.value ||
	// 		!form.current.message.value
	// 	) {
	// 		setError(true);
	// 		return;
	// 	} else {
	// 		setEmailSent("Sending");
	// 		emailjs
	// 			.sendForm(
	// 				"service_73ybjj2",
	// 				"template_q6hdejs",
	// 				form.current,
	// 				"ITT2iiUdtmFzYXSeh"
	// 			)
	// 			.then(
	// 				(result) => {
	// 					setEmailSent("Sent");
	// 					emailjs.sendForm(
	// 						"service_73ybjj2",
	// 						"template_qxuoiee",
	// 						form.current,
	// 						"ITT2iiUdtmFzYXSeh"
	// 					);
	// 					form.current.reset();
	// 				},
	// 				(error) => {
	// 					setEmailSent("NotSent");
	// 					console.log(error.text);
	// 				}
	// 			);
	// 	}
	// };

	// const onChange = (value) => {
	// 	if (value) {
	// 		setRecaptchad(true);

	// 		sendButton.classList.remove("disabled");
	// 		sendButton.style.opacity = "1";
	// 	}
	// };

	return (
		<>
			<StyledFooterContainer id="main-footer">
				<StyledFooter className="mt-16 mb-16">
					<div className="footer-logo-container">
						<img
							src={nfConsultBlack}
							alt="Website Logo"
							className="footer-logo"
							onClick={() => {
								window.location.href = "/";
							}}
						/>

						<div className="footer-info-container">
							<h4 className="footer-office-heading">Our office hours</h4>
							<div className="footer-office-hours">
								<h4 className="footer-office-hour">
									Monday - Friday: 8:00AM - 5:00PM
								</h4>
								<h4 className="footer-office-hour">
									Saturday & Sunday: Open to Emails
								</h4>
								<h4 className="footer-office-email">
									<a href="mailto:info@nfconsult.co.za">info@nfconsult.co.za</a>
								</h4>
							</div>
							<div className="footer-tag-line">
								NF Consult is here for all of your IT needs!
							</div>
						</div>
						<div className="footer-socials-container"></div>
					</div>
					<div className="footer-contact-area">
						<h2 className="footer-contact-header">Let's work together.</h2>
						<div className="contact-form">
							<form
								ref={form}
								onChange={detectTyping}
								className="contact-form"
								// onSubmit={sendEmail}
							>
								<div className="row-1">
									<div className="name-area">
										<label className="input-label" htmlFor="name">
											Name
										</label>
										<input
											type="text"
											name="user_name"
											placeholder="Name"
											className="contact-form-input"
										/>
									</div>
									<div className="name-area">
										<label className="input-label" htmlFor="email">
											Email
										</label>
										<input
											type="email"
											name="user_email"
											placeholder="Email"
											className="contact-form-input"
										/>
									</div>
								</div>
								<label className="input-label" htmlFor="message">
									Message
								</label>
								<textarea
									name="message"
									placeholder="Message"
									className="contact-form-input"
								></textarea>
								<div className="recaptcha-container">
									{/* <ReCAPTCHA
										size="normal"
										sitekey={siteKey}
										onChange={onChange}
									/> */}
								</div>

								<Button
									id="contact-form-button"
									type="submit"
									// disabled={!recaptchad}
									className="contact-form-button mt-4"
								>
									Send Email
								</Button>
								{error && (
									<p className="footer-error-message text-left mt-4 text-yellow-500 font-bold">
										Please fill in all fields
									</p>
								)}
							</form>
						</div>
					</div>
				</StyledFooter>
				<StyledDivider className="divider"></StyledDivider>
				<div className="footer-credits">
					<p className="mb-2">
						Designed and developed by &copy;{" "}
						<a
							href="https://www.woulfdigitalstudio.co.za"
							target="_blank"
							rel="noreferrer"
							className="designer-link"
						>
							Woulf Digital Studio
						</a>{" "}
						| {currentYear}
					</p>
				</div>
			</StyledFooterContainer>
		</>
	);
}
