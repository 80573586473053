import React from "react";
import { StyledTestimonialCard } from "./styled";

export default function TestimonialCard({
	image,
	imageAlt,
	name,
	title,
	quote,
	infoOrder,
	quoteOrder,
	company,
}) {
	return (
		<StyledTestimonialCard>
			<div className="testimonial-container">
				<div className="testimonial-info" style={{ order: infoOrder }}>
					<div className="testimonial-image">
						<img src={image} alt={imageAlt} />
					</div>
					<div className="testimonial-name">
						<h3>{name}</h3>
						<p>{title}</p>
						<p className="testimonial-company">{company}</p>
					</div>
				</div>
				<div className="testimonial-quote" style={{ order: quoteOrder }}>
					{quote.map((paragraph, index) => (
						<p key={index} className="testimonial-paragraph">
							{paragraph}
						</p>
					))}
				</div>
			</div>
		</StyledTestimonialCard>
	);
}
