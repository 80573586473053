import {
	SecurityRounded,
	SupportAgentRounded,
	CellTowerRounded,
	AlternateEmailRounded,
	DevicesRounded,
	CloudSyncRounded,
} from "@mui/icons-material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

export const services = [
	{
		number: "01",
		numberOrder: 1,
		numberBackground: "#05668D",
		contentOrder: 2,
		contentBackground: "#03364b",
		iconOrder: 3,
		title: "Desktop Support",
		description:
			"We offer desktop support. This includes on-site and remote support. ",
		icon: (
			<SupportAgentRounded className="service-card-infographic dark-blue" />
		),
	},
	{
		number: "02",
		numberOrder: 3,
		numberBackground: "#679436",
		contentOrder: 1,
		contentBackground: "#3b5b0c",
		iconOrder: 2,
		title: "Network Administration",
		description:
			"Deployment and configuration of new servers and network infrastructure.",
		icon: <CellTowerRounded className="service-card-infographic dark-green" />,
	},
	{
		number: "03",
		numberOrder: 2,
		numberBackground: "#188FA7",
		contentOrder: 3,
		contentBackground: "#0a6577",
		iconOrder: 1,
		title: "Network Security",
		description:
			"Ensure network integrity and protection from the outside world.",
		icon: <SecurityRounded className="service-card-infographic light-blue" />,
	},
	{
		number: "04",
		numberOrder: 1,
		numberBackground: "#7B6B43",
		contentOrder: 2,
		contentBackground: "#4a3b1f",
		iconOrder: 3,
		title: "Mail Server Management",
		description:
			"Monitor and maintain the company’s email system, whether on-site or cloud.",
		icon: (
			<AlternateEmailRounded className="service-card-infographic dark-brown" />
		),
	},
	{
		number: "05",
		numberOrder: 3,
		numberBackground: "#BB4430",
		contentOrder: 1,
		contentBackground: "#7b2e0b",
		iconOrder: 2,
		title: "Office365 Management",
		description: "We offer full Office356 organization administration.",
		icon: <MicrosoftIcon className="service-card-infographic dark-orange" />,
	},
	{
		number: "06",
		numberOrder: 2,
		numberBackground: "#EFC958",
		contentOrder: 3,
		contentBackground: "#b89e2b",
		iconOrder: 1,
		title: "Endpoint Management and Deployment",
		description:
			"Maintain functionality and safeguard devices, data, and other assets from cyber threats.",
		icon: <DevicesRounded className="service-card-infographic dark-yellow" />,
	},
	{
		number: "07",
		numberOrder: 1,
		numberBackground: "#7f6df5",
		contentOrder: 2,
		contentBackground: "#4d40a0",
		iconOrder: 3,
		title: "Backup & Redundancy",
		description:
			"Data loss prevention, quick redeployment and continuity of operations.",
		icon: <CloudSyncRounded className="service-card-infographic dark-purple" />,
	},
];
