import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Services from "./pages/services";
import Testimonials from "./pages/testimonials";

export default function Routers() {
	return (
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route exact path="/services" element={<Services />} />
			<Route exact path="/testimonials" element={<Testimonials />} />
		</Routes>
	);
}
