import styled from "styled-components";

export const StyledFloatingButton = styled.button`
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	z-index: 1000;
	background-color: var(--primary);
	transition: background-color 0.3s ease-in-out;
	border: none;
	padding: 0.5rem;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;

	display: ${(props) => (props.show === "show" ? "block" : "none")};
	animation: appear 0.5s ease-in-out;
	box-sizing: border-box;

	@keyframes appear {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.scroll-home {
		color: #ffffff;
	}
`;
