import styled from "styled-components";

export const StyledFooterContainer = styled.div`
	* {
		scroll-behavior: smooth !important;
	}
	margin-top: 2rem;

	scroll-margin-top: 4.5rem;

	@media screen and (max-width: 768px) {
		scroll-margin-top: 6.5rem;
	}

	.footer-credits {
		color: #000;
		padding: 0 0 1rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		font-weight: 500;
		text-align: center;
		word-spacing: 0.5rem;

		@media screen and (max-width: 768px) {
			width: 75%;
			margin: auto;
			padding: 0 0 2rem 0;
		}

		p {
			opacity: 0.5;
		}

		a {
			text-decoration: none;
			color: #000;
			transition: all 0.3s ease-in-out;

			&:hover {
				cursor: pointer;
			}
		}

		.designer-link {
			color: var(--primary);

			&:hover {
				opacity: 1 !important;
				color: var(--primary-dark);
			}
		}
	}
`;

export const StyledFooter = styled.div`
	color: #000;
	padding: 1rem 12rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 2rem;

	@media screen and (min-width: 1600px) {
		padding: 3rem 20rem;
	}

	@media screen and (max-width: 768px) {
		padding: 1rem 2rem;
		flex-direction: column;
	}

	.footer-logo-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		width: 75%;

		@media screen and (max-width: 768px) {
			order: 2;
			margin: 4rem auto 0rem;
			width: 100%;
		}
	}

	.footer-logo {
		width: 45%;
		height: auto;
	}

	.footer-info-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-office-hours {
		display: flex;
		flex-direction: column;
		row-gap: 0.5rem;
		margin-top: 1rem;

		.footer-office-hour {
			font-weight: 400;
		}

		.footer-office-email {
			a {
				font-weight: 400;
				text-decoration: none;
				color: var(--primary);

				&:hover {
					text-decoration: none;
					cursor: pointer;
					opacity: 1 !important;
				}
			}
		}

		@media screen and (max-width: 768px) {
			text-align: center;
		}
	}

	.footer-office-heading {
		margin-top: 2.5rem;
		font-size: 1.5rem;
		font-weight: 500;
		text-align: center;
		word-spacing: 0.5rem;
		width: fit-content;
	}

	.footer-tag-line {
		margin-top: 2.5rem;
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: 1rem;
		text-align: left;
		width: fit-content;
	}

	.footer-socials-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 1.5rem;
		column-gap: 2.5rem;

		.footer-social {
			width: 2rem;
			height: 2rem;
			transition: all 0.3s ease-in-out;
			border-radius: 20%;

			&:hover {
				cursor: pointer;
				transform: scale(1.35) !important;
				animation: shadow-blue 1s ease-in-out infinite;
			}
		}
	}

	.footer-contact-area {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;

		@media screen and (max-width: 768px) {
			order: 1;
		}
	}

	.footer-contact-header {
		font-size: 1.75rem;
		font-weight: 600;
		margin-bottom: 1rem;
		color: var(--primary);
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.row-1 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		column-gap: 1rem;
		margin-bottom: 1rem;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}

	.contact-form-input {
		width: 100%;
		padding: 1rem;
		margin-bottom: 1rem;
		border-radius: 0.5rem;
		margin-top: 1rem;
		text-align: left;
		border: none;
		outline: none;
		font-size: 1rem;
		font-weight: 500;
		background-color: #ffffff25;
		color: var(--primary);
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.09);

		&:focus {
			box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
			outline: 2px solid #869d7a;
		}
	}

	.input-label {
		font-size: 1rem;
		font-weight: 400;
		letter-spacing: 0.04rem;

		text-align: left;
		margin-right: auto;
	}

	.contact-form-button {
		width: 100%;
		height: 3rem;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white !important;

		&:hover {
			cursor: pointer;
			transform: scale(1.05) !important;
		}
	}

	.footer-error-message {
		margin-top: 1rem;
		color: #ff0000;
		font-weight: 600;
	}
`;

export const StyledDivider = styled.div`
	width: 100%;
	height: 0.25rem;
	background-color: var(--primary);
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	opacity: 0.5;
	scale: 0.5;
`;
