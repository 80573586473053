import styled from "styled-components";

export const StyledButtonContainer = styled.div`
	width: 100%;

	.disabled {
		opacity: 0;
		pointer-events: none !important;
		cursor: not-allowed !important;
	}
`;

export const StyledButton = styled.button`
	width: fit-content;
	transition: all 0.3s ease-in-out;
	border-radius: 10px;
	color: #fff;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
	padding: 0.5rem 1.5rem;
	box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
	border: none;
	background-color: var(--primary);

	&:hover {
		transform: scale(1.1);
		background-color: var(--primary-dark);
	}
`;
