import styled from "styled-components";

export const StyledServices = styled.section`
	.hero-greeting {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
		text-align: center;
	}

	.hero-tag-line {
		margin-top: -1rem;
		font-size: 1.25rem;
		font-weight: 300;
		text-align: center;
	}

	.services-section-container {
		scroll-margin-top: 4.5rem;
		padding: 3rem 12rem;

		@media screen and (max-width: 768px) {
			padding: 3rem 1rem 0;
		}

		@media screen and (min-width: 1600px) {
			padding: 3rem 20rem;
		}
	}

	.services-section-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.services-section-title {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 1rem;
		text-align: center;
	}

	.services-section-description {
		font-size: 1.2rem;
		font-weight: 300;
		text-align: center;
	}

	.services-section-card-container {
		display: flex;
		flex-direction: column;

		margin-top: 3rem;
		width: 100%;
	}

	.service-card-wrapper {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		box-sizing: border-box;
		width: 100%;
		margin-bottom: 1rem;
		box-shadow: 0 20px 75px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		transition: all 0.3s ease-in-out;

		&:hover {
			transform: scale(1.02);
		}

		@media screen and (max-width: 768px) {
			flex-direction: column;
			margin-bottom: 2rem;
		}

		.service-card-number {
			text-align: center;
			padding: 3rem;
			font-size: 3.5rem;
			font-weight: 600;
			color: #ffffff;
			width: 20%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;

			@media screen and (max-width: 768px) {
				width: 100%;
				order: 1 !important;
			}
		}

		.service-card-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 60%;
			color: #ffffff;
			padding: 2rem;
			box-sizing: border-box;

			@media screen and (max-width: 768px) {
				align-items: center;
				text-align: center;
				order: 2 !important;
				width: 100%;
			}

			.service-card-title {
				font-size: 1.75rem;
				font-weight: 600;
				margin-bottom: 1rem;
				text-align: left;

				@media screen and (max-width: 768px) {
					text-align: center;
				}
			}

			.service-card-description {
				font-size: 1.2rem;
				font-weight: 300;
				text-align: left;

				@media screen and (max-width: 768px) {
					text-align: center;
				}
			}
		}

		.service-card-icon {
			background-color: #ffffff;
			width: 20%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;

			@media screen and (max-width: 768px) {
				width: 100%;
				display: none;
			}
		}

		.service-card-infographic {
			font-size: 4rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}

		.dark-blue {
			color: #02212e;
		}

		.light-blue {
			color: #188fa7;
		}

		.dark-green {
			color: #3b5b0c;
		}

		.dark-brown {
			color: #4a3b1f;
		}

		.dark-orange {
			color: #7b2e0b;
		}

		.dark-purple {
			color: #4d40a0;
		}

		.dark-yellow {
			color: #b89e2b;
		}
	}
`;
