import React from "react";
import { StyledTestimonials } from "./styled";
import { testimonials } from "./testimonialsConfig";
import Slider from "react-slick";

export default function Testimonials() {
	const settings = {
		dots: false,
		infinite: true,
		arrows: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	const wrapText = (text) => {
		if (text.length > 150) {
			return text.slice(0, 150) + "...";
		} else {
			return text;
		}
	};

	return (
		<StyledTestimonials>
			<div className="testimonials-container" id="testimonials">
				<h1 className="testimonials-heading">What People Say About Us</h1>
				<div className="testimonials-card-container">
					<div className="testimonial-slider">
						<Slider {...settings}>
							{testimonials.map((testimonial, index) => (
								<div
									key={index}
									className="testimonials-card"
									onClick={() => {
										window.location.href = "/testimonials";
									}}
								>
									<p className="testimonials-description">
										"{wrapText(testimonial.desc[0])}"
									</p>

									<div className="testimonials-info-container">
										<div className="testimonials-image-desktop">
											<img src={testimonial.image} alt={testimonial.imageAlt} />
										</div>
										<div className="testimonials-sub-info">
											<p className="testimonials-name">{testimonial.name}</p>
											<p className="testimonials-position">
												{testimonial.position}
											</p>
											<p className="testimonials-company">
												{testimonial.company}
											</p>
										</div>
									</div>
									<div className="testimonials-image-mobile">
										<img src={testimonial.image} alt={testimonial.imageAlt} />
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>
		</StyledTestimonials>
	);
}
