import React, { useRef } from "react";
import { StyledServices } from "./styled";
import { Page, Hero } from "../../components";

import { services } from "./serviceConfig";

export default function Services() {
	const scrollToRef = useRef();

	const scrollTo = (element) => {
		scrollToRef.current = document.querySelector(element);

		if (scrollToRef.current) {
			scrollToRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<StyledServices>
			<Page>
				<Hero
					heroImage={
						"https://images.unsplash.com/photo-1518135714426-c18f5ffb6f4d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1796&q=80"
					}
					greeting={"We offer many IT services to suit your needs"}
					tagline={"Take a look at what we can do for you"}
					cta={"Learn more"}
					onClick={() => scrollTo(".services-section-container")}
				/>
				<section className="services-section-container">
					<div className="services-section-content">
						<h1 className="services-section-title">Our Services</h1>
						<p className="services-section-description">
							We offer a wide range of IT services to suit your needs.
						</p>

						<div className="services-section-card-container">
							{services.map((service, index) => (
								<div key={index} className="service-card-wrapper">
									<div
										className="service-card-number"
										style={{
											order: service.numberOrder,
											backgroundColor: service.numberBackground,
										}}
									>
										<div>{service.number}</div>
									</div>
									<div
										className="service-card-content"
										style={{
											order: service.contentOrder,
											backgroundColor: service.contentBackground,
										}}
									>
										<div>
											<div className="service-card-title">{service.title}</div>
											<div className="service-card-description">
												{service.description}
											</div>
										</div>
									</div>
									<div
										className="service-card-icon"
										style={{
											order: service.iconOrder,
										}}
									>
										{service.icon}
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			</Page>
		</StyledServices>
	);
}
