import styled from "styled-components";

export const StyledClients = styled.section`
	padding: 3rem 12rem;
	background-color: #dfdfdf;

	@media screen and (max-width: 768px) {
		padding: 3rem 2rem;
	}

	@media screen and (min-width: 1600px) {
		padding: 3rem 20rem;
	}

	.partner-container {
		margin-top: 5rem;
		padding-bottom: 2rem;
	}

	.partner-logos {
		margin-top: 4rem;
	}

	.clients-heading,
	.partner-heading {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 2rem;
		text-align: center;
	}

	.client,
	.partner-heading {
		margin: auto;
	}

	.clients-logo {
		margin: auto auto;
		height: 6rem;
		display: flex !important;
		align-items: stretch !important;

		img {
			margin: auto auto;
			width: 50%;
			height: auto;
			filter: grayscale(100%);
			transition: all 0.5s ease;

			@media screen and (max-width: 768px) {
				width: 85%;
				height: auto;
			}

			&:hover {
				filter: grayscale(0%);
			}
		}
	}

	.partner {
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.partner-logo {
		margin: auto auto;
		height: 6rem;
		display: flex !important;
		align-items: center !important;
		text-decoration: none !important;
		justify-content: center !important;
		color: var(--primary);
		filter: grayscale(100%);
		transition: all 0.5s ease;

		@media screen and (max-width: 768px) {
			width: 100%;
			height: auto;
			flex-direction: column;
		}

		.partner-solution {
			font-size: 1.25rem;
			font-weight: 400;
			text-align: left;
			width: 45%;
			margin-left: -6rem;
			margin-top: 2rem;

			@media screen and (max-width: 768px) {
				width: 100%;
				margin-left: 0rem;
				padding: 0 2rem;
				text-align: center;
			}
		}

		&:hover {
			filter: grayscale(0%);
		}

		img {
			margin: auto auto;
			width: 25%;
			height: auto;

			@media screen and (max-width: 768px) {
				width: 50%;
				height: auto;
			}
		}
	}

	#woulf-logo {
		img {
			width: 10%;
		}

		@media screen and (max-width: 768px) {
			img {
				width: 25%;
			}
		}

		.partner-solution {
			width: 60%;
			margin-left: -10rem;
			margin-top: 1.5rem;

			@media screen and (max-width: 768px) {
				width: 100%;
				margin: 1rem 2rem;
			}
		}
	}

	#kgb-logo {
		img {
			width: 30%;
		}

		@media screen and (max-width: 768px) {
			img {
				width: 50%;
			}
		}
	}

	#finhub-logo {
		img {
			width: 50%;
		}

		@media screen and (max-width: 768px) {
			img {
				width: 75%;
			}
		}
	}
`;
